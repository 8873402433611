import { animated, useSpring } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

type IProps = {
  children: React.ReactNode;
  className?: string;
  delay?: boolean;
  delayDuration?: number;
  yOffset: number;
  duration?: number;
};

const SlideUp = ({ children, className, delay, yOffset, duration, delayDuration }: IProps) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.2,
    triggerOnce: true,
  });

  const delayFn = (delayDuration?: number, delay?: boolean) => {
    if (delay) {
      return delayDuration ?? 200;
    }
    return 0;
  };

  const slideUp = useSpring({
    from: { transform: `translateY(${yOffset}%)`, opacity: 0 },
    to: {
      transform: inView ? 'translateY(0%)' : `translateY(${yOffset}%)`,
      opacity: inView ? 1 : 0,
      config: {
        duration: duration ?? 700,
      },
    },
    delay: delayFn(delayDuration, delay),
  });
  // use a custom tag for the animated dom
  return (
    <animated.p ref={ref} className={className} style={slideUp}>
      {children}
    </animated.p>
  );
};

export default SlideUp;
