import SlideUpP from '../../common/utils/slideUpP';

const Mof = () => {
  return (
    <div className={'flex w-full items-center justify-center bg-caramel py-4 text-dacquoise'}>
      <SlideUpP yOffset={-15} className={'font-stigsaMedium text-3xl uppercase tracking-wide'}>
        Meilleur ouvrier de France
      </SlideUpP>
    </div>
  );
};

export default Mof;
